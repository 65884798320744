import { Link } from 'react-router-dom';

export default function Faq() {
  return (
    <>
        <div className=" inner-heading">
            <div className="container sml-container">
                <h2 className="medium-heading text-black">Frequently Asked Questions</h2>
            </div>
        </div>
        
        <div className="faq-section py-96px">
            <div className="container sml-container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <h2 className="fs-40 fw-600 text-center text-black faq-header">Frequently asked questions (FAQ)</h2>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button fs-24 fw-500 text-black" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        What is Analyxus?
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <p className="text-gray fs-16 fw-400 mb-3">Analyxus is a platform that provides a series of analysis methods for tabular data. In particular, the platform offers state-of-art deep learning methods for analyzing a variety of types of tabular data, such as biomedical sensory data, clinical outcome data, single-cell data, and other omics data. Click "Get Started"to learn more and see example use cases.</p>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}
