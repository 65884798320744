import { Link } from "react-router-dom";

export default function FeatureEmbeddingMachine() {
  return (
    <>
      <div className="inner-heading">
        <div className="container sml-container">
          <h2 className="medium-heading text-black"> Tabular data analysis </h2>
        </div>
      </div>

      <section className="tools-section tools-spacing">
        <div className="tools-tabs position-relative">
          <div className="container sml-container">
            <div className="tab-content" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-genomaps" role="tabpanel" aria-labelledby="pills-genomaps-tab" tabIndex={0}>
                <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                  <div className="left">
                    <h3 className="fs-32 text-black fw-500 mb-24px"> Tabular data analysis </h3>
                    <p className="text-gray">
                    Tabular data are ubiquitously employed across disciplines for a wide range of applications.
A set of tabular data comprises data samples as rows and sample features as columns.
While the format is convenient, the tabular representation falls short in helping us to apprehend the underlying associations among the data, which greatly hampers the downstream pattern discovery and predictive analytics.
Here, we develop a broadly applicable strategy to unravel the intertwined relationships among the data by reconfiguring each data sample into a spatially semantic 2D topographic map, referred to as TabMap.
A TabMap preserves the original feature values as pixel intensities, with the relationships among the features encoded in the contexture of the map.
The introduction of TabMap makes it possible to apply 2D convolutional neural networks to extract the underlying data association patterns for effective data analysis.
                    </p>

                    <p className="text-gray">Reference: Yan and Xing et al., Spatially semantic topography of tabular data according to feature inter-relationships enables interpretable deep pattern discovery, under review.</p>
                    <Link to="/geno-map" className="button button__primary mt-4"> See example </Link>
                  </div>
                  <div className="right">
                    <img src="assets/img/tabulardata2.png" alt="toool image" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
