import { Link } from 'react-router-dom';

export default function AboutUs() {
  return (
    <>
        <div className="inner-heading">
            <div className="container sml-container">
                <h2 className="medium-heading text-black">About us</h2>
            </div>
        </div>
        <div className="about-section">
            <div className="container sml-container">
            <div className="row">
                <div className="col-md-6 about-left mb-3">
                    <div className="position-relative content">
                        <img src="assets/img/about.png" alt="about us" className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-6 about-right mb-3">
                    <h3 className="text-black fw-600 fs-40 mb-24px">Overview</h3>
                    <p className="mb-24px text-gray font-dm">
                        Analyxus is a data analysis tool from Xing Lab @ Stanford, designed for analyzing and visualizing high dimensional data across desciplines and applications (e.g., scRNA-sequences, features data of deep neural networks and clinical trial data).
                    </p>
                   
                    <div className="mt-4">
                        <Link to="/" className="button button__primary">See More</Link>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </>
  );
}
