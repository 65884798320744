import { Link } from 'react-router-dom';
import { tutorialLink } from '../../config';

export default function Tutorial() {
  return (
    <>
        <div className=" inner-heading">
            <div className="container sml-container">
            <h2 className="medium-heading text-black">Tutorial</h2>
            </div>
        </div>

        <div className="tutorial-section py-96px">
            <div className="container sml-container">
                <div className="row items mb-4">
                    <div className="col-md-6 items-left mb-3">
                        <h3 className="text-black fs-40 fw-500 mb-24px">Getting started</h3>
                        <ul className="list">
                            <li className="list-item fs-24 text-gray mb-3">Check out our video to get an overview of how to analyze your single-cell RNA sequencing data with Analyxus</li>
                        </ul>
                    </div>
                    <div className="col-md-6 items-right mb-3">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Z8O0e5PerVM?si=DBrjWE_faN26uyJ8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>                    </div>
                </div>
                
            </div>
        </div>
    </>
  );
}
