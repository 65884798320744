import { Link } from 'react-router-dom';

export default function ContrastiveFeatureAnalysis() {
  return (
    <>
        <div className="inner-heading">
            <div className="container sml-container">
                <h2 className="medium-heading text-black">Neural network feature analysis</h2>
            </div>
        </div>
        <section className="tools-section tools-spacing">
            <div className="tools-tabs position-relative">
                <div className="container sml-container">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active " id="pills-genomaps" role="tabpanel" aria-labelledby="pills-genomaps-tab" tabIndex={0}>
                            <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
                                <div className="left">
                                    <h3 className="fs-32 text-black fw-500 mb-24px">Neural network feature analysis</h3>
                                    <p className="text-gray">Deep neural networks (DNNs) extract thousands to millions of task-specific features during model training for inference and
decision-making. While visualizing these features is critical for comprehending the learning process and improving the performance of the DNNs, existing visualization techniques work only for classification tasks. For regressions, the feature points
lie on a high dimensional continuum having an inherently complex shape, making a meaningful visualization of the features
intractable. Given that the majority of deep learning applications are regression-oriented, developing a conceptual framework
and computational method to reliably visualize the regression features is of great significance. Here, we introduce a manifold
discovery and analysis (MDA) method for DNN feature visualization, which involves learning the manifold topology associated
with the output and target labels of a DNN. MDA leverages the acquired topological information to preserve the local geometry of the feature space manifold and provides insightful visualizations of the DNN features, highlighting the appropriateness, generalizability, and adversarial robustness of a DNN. MDA is also applicable for analyzing deep learning features for classification tasks and performs substantially better than existing methods.</p>

<p className="text-gray">Reference: Islam, M. T. and Xing, L. et al. Revealing hidden patterns in deep neural network feature space continuum via manifold learning. Nature Communications, in press (2023).</p>
                                    <Link to="/" className="button button__primary mt-4">See example</Link>
                                </div>
                            <div className="right">
                                <img src="assets/img/nnfeature2.png" alt="toool image" className="img-fluid" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}
