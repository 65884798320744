export default function ContactUs() {
  return (
    <>
        <div className=" inner-heading">
            <div className="container sml-container">
            <h2 className="medium-heading text-black">Contact us</h2>
            </div>
        </div>
 
        <div className="contact-section py-96px">
            <div className="container sml-container">
                <div className="contact-section__content">
                    <div className="contact-box">
                        <div className="box-item d-flex align-items-start secondery-bg">
                            <svg width={56} height={56} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1_800)">
                                    <path d="M27.9994 14C26.1534 14 24.3489 14.5474 22.814 15.573C21.2792 16.5985 20.0829 18.0562 19.3765 19.7616C18.6701 21.4671 18.4852 23.3437 18.8454 25.1542C19.2055 26.9647 20.0944 28.6277 21.3997 29.933C22.705 31.2383 24.368 32.1272 26.1785 32.4873C27.989 32.8475 29.8656 32.6626 31.5711 31.9562C33.2765 31.2498 34.7342 30.0535 35.7597 28.5187C36.7853 26.9838 37.3327 25.1793 37.3327 23.3333C37.3327 20.858 36.3494 18.484 34.599 16.7337C32.8487 14.9833 30.4747 14 27.9994 14ZM27.9994 28C27.0764 28 26.1741 27.7263 25.4067 27.2135C24.6393 26.7007 24.0411 25.9719 23.6879 25.1192C23.3347 24.2665 23.2423 23.3282 23.4224 22.4229C23.6024 21.5177 24.0469 20.6861 24.6995 20.0335C25.3522 19.3809 26.1837 18.9364 27.0889 18.7563C27.9942 18.5763 28.9325 18.6687 29.7852 19.0219C30.6379 19.3751 31.3668 19.9732 31.8795 20.7407C32.3923 21.5081 32.666 22.4104 32.666 23.3333C32.666 24.571 32.1744 25.758 31.2992 26.6332C30.424 27.5083 29.237 28 27.9994 28Z" fill="#090202" />
                                    <path d="M28.0007 55.9998C26.0359 56.0098 24.0973 55.549 22.3472 54.6559C20.5971 53.7628 19.0865 52.4634 17.9417 50.8665C9.0494 38.6001 4.53906 29.3788 4.53906 23.4568C4.53906 17.2344 7.01091 11.2668 11.4108 6.86688C15.8107 2.46696 21.7783 -0.00488281 28.0007 -0.00488281C34.2232 -0.00488281 40.1907 2.46696 44.5906 6.86688C48.9906 11.2668 51.4624 17.2344 51.4624 23.4568C51.4624 29.3788 46.9521 38.6001 38.0597 50.8665C36.915 52.4634 35.4044 53.7628 33.6542 54.6559C31.9041 55.549 29.9655 56.0098 28.0007 55.9998ZM28.0007 5.08878C23.1297 5.09434 18.4598 7.03181 15.0154 10.4762C11.5711 13.9205 9.63362 18.5904 9.62806 23.4615C9.62806 28.1515 14.0451 36.8245 22.0624 47.8821C22.743 48.8196 23.6359 49.5826 24.6681 50.1087C25.7002 50.6349 26.8422 50.9092 28.0007 50.9092C29.1592 50.9092 30.3013 50.6349 31.3334 50.1087C32.3655 49.5826 33.2584 48.8196 33.9391 47.8821C41.9564 36.8245 46.3734 28.1515 46.3734 23.4615C46.3678 18.5904 44.4304 13.9205 40.986 10.4762C37.5417 7.03181 32.8718 5.09434 28.0007 5.08878Z" fill="#090202" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_800">
                                        <rect width={56} height={56} fill="white" />
                                    </clipPath>
                                </defs>
                            </svg> 
                            <div className="ms-3">
                                <p className="fs-24 fw-500 mb-1">Email</p>
                                <p className="mb-0 text-gray fs-16 fw-400 ">tauhid@stanford.edu</p>
                            </div>                      
                        </div>
                       
                       
                    </div>
                </div>
            </div>

            <div className="container sml-container">
                <div className="contact-form">
                    <h3 className="fs-32 fw-500 text-black mb-5">Send us an inquiry below</h3>
                    <form>
                        <div className="inline-form row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="firstName" className="fs-18 d-block text-black font-dm">First name</label>
                                <input type="text" id="firstName" />
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="lastName" className="fs-18 d-block text-black font-dm">Last name</label>
                                <input type="text" id="lastName" />
                            </div>
                        </div>
                        <div className="inline-form row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="firstName" className="fs-18 d-block text-black font-dm">Email address</label>
                                <input type="text" id="firstName" />
                            </div>
                            <div className="form-group col-sm-6">
                                <label htmlFor="lastName" className="fs-18 d-block text-black font-dm">Phone number</label>
                                <input type="text" id="lastName" />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="Message" className="fs-18 d-block text-black font-dm">Message</label>
                            <textarea name="message" id="Message" cols={30} rows={10} defaultValue={""} />
                        </div>
                        <a href="mailto:tauhid@stanford.edu?subject=Analyxus&body=message%20goes%20here">
  <button className="button button__primary mt-3">Send Email</button>
</a>                    

</form>
                </div>
            </div>
        </div>
    </>
  );
}
